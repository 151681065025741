import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { TradingNavigatorParamList } from '@south-street-app/navigation/types';
import type { TradeRequestComponentDto } from '@utility-nyc/react-query-sdk';

import { useCallback, useEffect, useState } from 'react';

import { Keyboard } from 'react-native';

import { useMedia, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { MINIMUM_TRADE_AMOUNT } from '@shared/services';
import { capitalizeFirstLetter, moneyStringToNumber } from '@shared/utils';
import { HeadingM } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';

import { PreviewTradeButton } from './PreviewTradeButton';
import { SwapButtom } from './SwapButton';
import { TradeItem } from './TradeItem';
import { combinedProductName } from './combinedProductName';

type SwapTradeOrderProps = {
  navigation: NativeStackNavigationProp<
    TradingNavigatorParamList,
    'TradeOrder'
  >;
};

const SwapTradeOrder = ({ navigation }: SwapTradeOrderProps) => {
  const { proposal, setProposal } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      setProposal: state.setProposal,
    })),
  );
  const { desktop } = useMedia();
  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );

  const firstProduct = proposal.components[0].product;

  const [topTradeInput, setTopTradeInput] = useState<
    Omit<TradeRequestComponentDto, 'price'>
  >({
    type: 'OFFER',
    product: {
      name: firstProduct.name,
      coupon: firstProduct.coupon,
      month: firstProduct.month,
      bidPrice: firstProduct.bidPrice,
      askPrice: firstProduct.askPrice,
      id: firstProduct.id,
      settlementDate: firstProduct.settlementDate,
      cusip: firstProduct.cusip,
      maturityDate: firstProduct.maturityDate,
    },
    amount: 0,
  });
  const [bottomTradeInput, setBottomTradeInput] = useState<
    Omit<TradeRequestComponentDto, 'price'>
  >({
    type: 'BID',
    product: {
      name: '-',
      coupon: 0,
      month: 1,
      bidPrice: { par: 0, fraction: 0 },
      askPrice: { par: 0, fraction: 0 },
      id: '',
      settlementDate: '',
      cusip: '',
      maturityDate: '',
    },
    amount: 0,
  });

  useEffect(() => {
    if (
      proposal.components.length > 1 &&
      proposal.components[1].product.name !== bottomTradeInput.product.name
    ) {
      setBottomTradeInput({
        ...bottomTradeInput,
        product: {
          ...bottomTradeInput.product,
          name: proposal.components[1].product.name,
          coupon: proposal.components[1].product.coupon,
          month: proposal.components[1].product.month,
          bidPrice: proposal.components[1].product.bidPrice,
          askPrice: proposal.components[1].product.askPrice,
          id: proposal.components[1].product.id,
          settlementDate: proposal.components[1].product.settlementDate,
          cusip: proposal.components[1].product.cusip,
          maturityDate: proposal.components[1].product.maturityDate,
        },
      });
    }
  }, [bottomTradeInput, proposal.components]);

  const handleSwap = useCallback(() => {
    setTopTradeInput({
      ...topTradeInput,
      type: bottomTradeInput.type,
    });

    setBottomTradeInput({
      ...bottomTradeInput,
      type: topTradeInput.type,
    });
  }, [topTradeInput, bottomTradeInput]);

  const handlePreviewTrade = useCallback(() => {
    const bidPrice = topTradeInput.product.bidPrice
      ? {
          par: moneyStringToNumber(
            topTradeInput.product.bidPrice.par.toString(),
          ),
          fraction: topTradeInput.product.bidPrice.fraction,
        }
      : undefined;
    const askPrice = bottomTradeInput.product.askPrice
      ? {
          par: moneyStringToNumber(
            bottomTradeInput.product.askPrice.par.toString(),
          ),
          fraction: bottomTradeInput.product.askPrice.fraction,
        }
      : undefined;

    setProposal({
      ...proposal,
      components: [
        {
          ...topTradeInput,
          price: bidPrice,
        },
        {
          ...bottomTradeInput,
          price: askPrice,
        },
      ],
    });

    navigation.navigate('PreviewTradeOrder');
  }, [bottomTradeInput, navigation, proposal, setProposal, topTradeInput]);

  const handleOpenProductList = useCallback(() => {
    Keyboard.dismiss();

    openBottomSheet({
      type: 'productList',
    });
  }, [openBottomSheet]);

  const handleTopTradeInputOnChangeValue = useCallback(
    (amount: string) => {
      setTopTradeInput({
        ...topTradeInput,
        amount: moneyStringToNumber(amount),
      });
    },
    [topTradeInput],
  );

  const handleBottomTradeInputOnChangeValue = useCallback(
    (amount: string) => {
      setBottomTradeInput({
        ...bottomTradeInput,
        amount: moneyStringToNumber(amount),
      });
    },
    [bottomTradeInput],
  );

  console.log(topTradeInput.amount, bottomTradeInput.amount, 'amount');

  return (
    <YStack
      flex={1}
      justifyContent={'space-between'}
      {...(desktop && {
        justifyContent: 'flex-start',
        width: 800,
        marginHorizontal: '$auto',
      })}
    >
      <HeadingM fontWeight={'$3'} paddingTop={'$2'} paddingBottom={'$10'}>
        {en_US.swap}
      </HeadingM>
      <YStack
        flex={desktop ? undefined : 1}
        {...(desktop && {
          paddingBottom: '$16',
        })}
        space={'$4'}
      >
        <TradeItem
          label={capitalizeFirstLetter(topTradeInput.type)}
          product={combinedProductName(topTradeInput)}
          onChangeValue={handleTopTradeInputOnChangeValue}
        />
        <YStack paddingVertical={'$1'}>
          <SwapButtom onPress={handleSwap} />
        </YStack>
        <TradeItem
          disabled={topTradeInput.amount < 1}
          label={capitalizeFirstLetter(bottomTradeInput.type)}
          product={combinedProductName(bottomTradeInput)}
          onPressChooseProduct={handleOpenProductList}
          onPressChangeProduct={handleOpenProductList}
          onChangeValue={handleBottomTradeInputOnChangeValue}
          isProductChangeable={true}
        />
      </YStack>
      <PreviewTradeButton
        onPress={handlePreviewTrade}
        isReadyToPreview={
          topTradeInput.amount >= MINIMUM_TRADE_AMOUNT &&
          bottomTradeInput.amount >= MINIMUM_TRADE_AMOUNT
        }
      />
    </YStack>
  );
};

export { SwapTradeOrder };

import { XStack, YStack } from 'tamagui';

import { BodyL, LabelL } from '@south-street-app/atoms';

const DisplayTradeItem = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <YStack>
    <XStack justifyContent={'space-between'} paddingVertical={'$4'}>
      <LabelL fontWeight={'$2'}>{label}</LabelL>
      <BodyL>{value}</BodyL>
    </XStack>
    <YStack height={'$px'} backgroundColor={'$mono300'} />
  </YStack>
);

export { DisplayTradeItem };

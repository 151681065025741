import type { Conversation } from '@twilio/conversations';
import type {
  TradeComponentInputDto,
  TradeRequestDtoType,
  TradeRequestProposalDto,
} from '@utility-nyc/react-query-sdk';
import type { IMessage } from 'react-native-gifted-chat';

import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type CreateTradeRequestDto = {
  type: TradeRequestDtoType;
  components: TradeComponentInputDto[];
};

type MobileTradeStoreState = {
  conversation: Conversation | null;
  chatConversation: Conversation | null;
  tradeType: TradeRequestDtoType;
  tradeProposalId: string | null;
  proposal: TradeRequestProposalDto;
  persistantMessages: IMessage[];
  counterProposal: TradeRequestProposalDto;
  receivedQuotedPrice: boolean;
  tradeTimeout: number | undefined;
  chatAuthor: string;
  chatTrader: string;
  endUserId: string;
  tradeRequestId: string;
  isNewQuotedPrice: boolean;
  setPersistantMessages: (persistantMessages: IMessage[]) => void;
  clearPersistantMessages: () => void;
  getTradeRequestId: () => string;
  setConversation: (conversation: Conversation) => void;
  setChatConversation: (conversation: Conversation) => void;
  setTradeType: (tradeType: TradeRequestDtoType) => void;
  setTradeProposalId: (tradeProposalId: string) => void;
  setProposal: (proposal: TradeRequestProposalDto) => void;
  setCounterProposal: (counterProposal: TradeRequestProposalDto) => void;
  setReceivedQuotedPrice: (receivedQuotedPrice: boolean) => void;
  setIsNewQuotedPrice: (isNewQuotedPrice: boolean) => void;
  setTradeTimeout: (tradeTimeout: number | undefined) => void;
  createTradeRequest: (trade: CreateTradeRequestDto) => void;
  acceptCounterproposal: () => void;
  rejectCounterproposal: () => void;
  cancelTrade: () => void;
  sendChatMessage: (message: string) => void;
  setChatAuthor: (author: string) => void;
  setChatTrader: (trader: string) => void;
  setEndUserId: (endUserId: string) => void;
  setTradeRequestId: (tradeRequestId: string) => void;
};

enum MessageType {
  CreateTradeRequest = 'create-trade-request',
  AcceptCounterproposal = 'accept-counterproposal',
  RejectCounterproposal = 'reject-counterproposal',
  TradeRequestCreated = 'trade-request-created',
  TradeRequestStateChanged = 'trade-request-state-changed',
  TradeRequestExecuted = 'trade-request-executed',
  TradeRequestCanceled = 'trade-request-canceled',
  CancelTradeRequest = 'cancel-trade-request',
}

const useMobileTradeStore = createWithEqualityFn<MobileTradeStoreState>(
  (set, get) => ({
    persistantMessages: [],
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    conversation: get()?.conversation,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    chatConversation: get()?.chatConversation,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    tradeType: get()?.tradeType,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    tradeProposalId: get()?.tradeProposalId,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    proposal: get()?.proposal,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    counterProposal: get()?.counterProposal,
    tradeTimeout: 180,
    receivedQuotedPrice: false,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    chatAuthor: get()?.chatAuthor,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    chatTrader: get()?.chatTrader,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    endUserId: get()?.endUserId,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    tradeRequestId: get()?.tradeRequestId,
    isNewQuotedPrice: false,
    setPersistantMessages: (persistantMessages) => {
      set({ persistantMessages });
    },
    clearPersistantMessages: () => set({ persistantMessages: [] }),
    getTradeRequestId: () => get().tradeRequestId,
    setConversation: (conversation) => set({ conversation }),
    setChatConversation: (conversation) =>
      set({ chatConversation: conversation }),
    setTradeType: (tradeType) => set({ tradeType }),
    setTradeProposalId: (tradeProposalId) => set({ tradeProposalId }),
    setProposal: (proposal) => set({ proposal }),
    setCounterProposal: (counterProposal) => set({ counterProposal }),
    setReceivedQuotedPrice: (receivedQuotedPrice) =>
      set({ receivedQuotedPrice }),
    setIsNewQuotedPrice: (isNewQuotedPrice) => set({ isNewQuotedPrice }),
    setTradeTimeout: (tradeTimeout) => set({ tradeTimeout }),
    setChatAuthor: (author) => set({ chatAuthor: author }),
    setChatTrader: (trader) => set({ chatTrader: trader }),
    createTradeRequest: async (trade) => {
      const conversation = get().conversation;

      if (!conversation) {
        return;
      }

      try {
        conversation.sendMessage(
          JSON.stringify({
            type: MessageType.CreateTradeRequest,
            data: trade,
          }),
        );
      } catch (error) {
        console.log(error, 'error');
      }
    },
    acceptCounterproposal: () => {
      const conversation = get().conversation;
      const proposalId = get().tradeProposalId;

      if (!conversation) {
        return;
      }

      conversation.sendMessage(
        JSON.stringify({
          type: MessageType.AcceptCounterproposal,
          data: {
            tradeProposalId: proposalId,
          },
        }),
      );
    },
    rejectCounterproposal: () => {
      const conversation = get().conversation;
      const proposalId = get().tradeProposalId;

      if (!conversation) {
        return;
      }

      conversation.sendMessage(
        JSON.stringify({
          type: MessageType.RejectCounterproposal,
          data: {
            tradeProposalId: proposalId,
          },
        }),
      );
    },
    cancelTrade: () => {
      const conversation = get().conversation;
      const tradeRequestId = get().tradeRequestId;
      const endUserId = get().endUserId;

      if (!conversation) {
        return;
      }

      conversation.sendMessage(
        JSON.stringify({
          type: MessageType.CancelTradeRequest,
          data: {
            tradeRequestId,
            endUserId,
          },
        }),
      );

      set({ tradeRequestId: '' });
    },
    sendChatMessage: (message) => {
      const convo = get().chatConversation;

      if (!convo) {
        return;
      }

      try {
        convo.sendMessage(message);
      } catch (error) {
        console.log(error, 'error');
      }
    },
    setEndUserId: (endUserId) => {
      set({ endUserId });
    },
    setTradeRequestId: (tradeRequestId) => {
      set({ tradeRequestId });
    },
  }),
  shallow,
);

export { useMobileTradeStore, MessageType };

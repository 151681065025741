import type { TradingNavigatorParamList } from './types';

import { Platform, StyleSheet } from 'react-native';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { useMedia, YStack } from 'tamagui';

import { Icon } from '@south-street-app/atoms';
import {
  TradeOrderScreen,
  PreviewTradeOrderScreen,
  TradeConfirmationScreen,
  TradeTimeoutScreen,
} from '@south-street-app/screens';

const { Navigator, Screen } =
  createBottomTabNavigator<TradingNavigatorParamList>();

const styles = StyleSheet.create({
  pressStyle: {
    opacity: 0.7,
  },
});

const HeaderLeft = ({ isBackButton = false }: { isBackButton?: boolean }) => {
  const { goBack } = useNavigation();

  return (
    <YStack
      paddingVertical={'$3'}
      paddingLeft={'$2'}
      onPress={goBack}
      {...(Platform.OS === 'web' ? { cursor: 'pointer' } : undefined)}
      pressStyle={styles.pressStyle}
    >
      <Icon
        color={'$mono700'}
        size={'$6'}
        iconName={isBackButton ? 'chevronLeftIcon' : 'closeIcon'}
      />
    </YStack>
  );
};

const HeaderLeftClose = () => HeaderLeft({ isBackButton: false });
const HeaderLeftBack = () => HeaderLeft({ isBackButton: true });

const TradingStackNavigator = () => {
  const { desktop } = useMedia();

  return (
    <Navigator
      initialRouteName={'TradeOrder'}
      screenOptions={{
        headerShown: true,
      }}
    >
      <Screen
        name={'TradeOrder'}
        options={{
          headerTitle: 'Trade Order',
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
          headerLeft: HeaderLeftClose,
          tabBarStyle: { display: 'none' },
          headerStyle: {
            shadowColor: 'transparent',
            elevation: 0,
          },
        }}
        component={TradeOrderScreen}
      />
      <Screen
        name={'PreviewTradeOrder'}
        options={{
          headerTitle: 'Trade Order',
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
          headerLeft: HeaderLeftBack,
          tabBarStyle: { display: 'none' },
          headerStyle: {
            shadowColor: 'transparent',
            elevation: 0,
          },
        }}
        component={PreviewTradeOrderScreen}
      />
      <Screen
        name={'TradeConfirmation'}
        options={{
          headerTitle: 'Trade Information',
          tabBarStyle: { display: 'none' },
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
          headerStyle: {
            shadowColor: 'transparent',
            elevation: 0,
          },
        }}
        component={TradeConfirmationScreen}
      />
      <Screen
        name={'TradeTimeout'}
        options={{ headerShown: false, tabBarStyle: { display: 'none' } }}
        component={TradeTimeoutScreen}
      />
    </Navigator>
  );
};

export { TradingStackNavigator };

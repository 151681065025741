import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { TradingNavigatorParamList } from '@south-street-app/navigation/types';

import { useMobileTradeStore } from '@south-street-app/stores';

import { RolltradeOrder } from './RollTradeOrder';
import { SingleTradeOrder } from './SingleTradeOrder';
import { SwapTradeOrder } from './SwapTradeOrder';

type SwitchTradeRenderProps = {
  navigation: NativeStackNavigationProp<
    TradingNavigatorParamList,
    'TradeOrder'
  >;
};

const SwitchTradeRender = ({ navigation }: SwitchTradeRenderProps) => {
  const tradeType = useMobileTradeStore((state) => state.tradeType);

  switch (tradeType) {
    case 'SWAP':
      return <SwapTradeOrder navigation={navigation} />;
    case 'ROLL':
      return <RolltradeOrder navigation={navigation} />;
    default:
      return <SingleTradeOrder navigation={navigation} />;
  }
};

export { SwitchTradeRender };

import { useCallback } from 'react';

import { useMedia, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { en_US } from '@south-street-app/configs';
import { SlideButton } from '@south-street-app/molecules';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';

import { DisplayTradeInfo } from './DisplayTradeInfo';

const SinglePreviewTradeOrder = () => {
  const { desktop } = useMedia();
  const { createTradeRequest, setReceivedQuotedPrice, proposal, tradeType } =
    useMobileTradeStore(
      useShallow((state) => ({
        proposal: state.proposal,
        createTradeRequest: state.createTradeRequest,
        setReceivedQuotedPrice: state.setReceivedQuotedPrice,
        tradeType: state.tradeType,
      })),
    );

  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );

  const firstComponent = proposal.components[0];

  const handleOnCompleted = useCallback(() => {
    try {
      setReceivedQuotedPrice(false);

      createTradeRequest({
        components: [
          {
            productId: firstComponent.product.id,
            amount: firstComponent.amount,
            type: firstComponent.type,
          },
        ],
        type: tradeType,
      });

      openBottomSheet({
        type: 'requestTrade',
      });
    } catch (error) {
      console.log(error);
    }
  }, [
    createTradeRequest,
    firstComponent,
    openBottomSheet,
    setReceivedQuotedPrice,
    tradeType,
  ]);

  return (
    <YStack
      flex={1}
      justifyContent={'space-between'}
      {...(desktop && {
        width: 800,
        marginHorizontal: '$auto',
        justifyContent: 'flex-start',
        gap: '$16',
      })}
    >
      <DisplayTradeInfo order={proposal.components[0]} />
      <SlideButton
        label={en_US.submitTradeRequest}
        onCompleted={handleOnCompleted}
      />
    </YStack>
  );
};

export { SinglePreviewTradeOrder };

import type { TradeRequestDto } from '@utility-nyc/react-query-sdk';

import { useMemo } from 'react';

import { XStack, YStack } from 'tamagui';

import { capitalizeFirstLetter } from '@shared/utils';
import { LabelL, LabelM } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';

import { CounterproposalContent } from './CounterproposalContent';

type HistoryListItemProps = {
  item: TradeRequestDto;
};

const dateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: undefined,
} as const;

const HistoryListItem = ({ item }: HistoryListItemProps) => {
  const formattedStatus = useMemo(() => {
    if (item.status === 'CANCELED') {
      return item.canceledByTrader
        ? en_US.canceledByTrader
        : en_US.canceledByCustomer;
    }

    return capitalizeFirstLetter(item.status.replace('_', ' '));
  }, [item.status, item.canceledByTrader]);

  const formattedDate = useMemo(
    () => new Date(item.createdAt).toLocaleDateString('en-US', dateOptions),
    [item.createdAt],
  );

  const formattedType = useMemo(
    () => capitalizeFirstLetter(item.type),
    [item.type],
  );

  const counterproposal = useMemo(
    () =>
      item.counterproposal?.components.map((component, index) => (
        <CounterproposalContent
          key={index}
          component={component}
          index={index}
          isMoreThanOneComponent={
            item.counterproposal?.components !== undefined &&
            item.counterproposal.components.length > 1
          }
          isLastComponent={
            index === (item.counterproposal?.components.length ?? 0) - 1
          }
          isExecuted={item.status === 'EXECUTED'}
        />
      )),
    [item.counterproposal?.components, item.status],
  );

  return (
    <YStack
      width={'$100%'}
      paddingHorizontal={'$4'}
      paddingTop={'$9.5'}
      paddingBottom={'$6'}
      borderBottomWidth={'$px'}
      borderBottomColor={'$mono300'}
    >
      <LabelM fontWeight={'$2'} color={'$mono900'}>
        {formattedDate}
      </LabelM>
      <XStack justifyContent={'space-between'} paddingTop={'$6'}>
        <LabelL fontWeight={'$2'}>{formattedType}</LabelL>
        <LabelM fontWeight={'$2'}>{formattedStatus}</LabelM>
      </XStack>
      <YStack paddingLeft={'$4'}>{counterproposal}</YStack>
    </YStack>
  );
};

export { HistoryListItem };
export type { HistoryListItemProps };

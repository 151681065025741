export * from './platform';
export * from './formatToMoney';
export * from './isRefObject';
export * from './getColorByIndexWithCycle';
export * from './moneyStringToNumber';
export * from './getShortMonthName';
export * from './quote-number';
export * from './capitalizeFirstLetter';
export * from './zustandStorage';
export * from './getInitials';
export * from './toast';
export * from './formattedProductName';
export * from './formattedDate';
export * from './formattedTradeDetails';
export * from './getProductsByTypeOfTrade';

import type { NavigationProp } from '@react-navigation/native';
import type { PrivateStackNavigatorParamList } from '@south-street-app/navigation/types';
import type { CreateTradeRequestDtoType } from '@utility-nyc/react-query-sdk';

import { useCallback } from 'react';

import { Pressable } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { useMedia, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { getShortMonthName } from '@shared/utils';
import { BodyL, BodyM, HeadingL, Icon, LabelL } from '@south-street-app/atoms';
import { ButtonLarge } from '@south-street-app/molecules';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';
import { getSizeTokenValue } from '@south-street-app/theme';

const OperationsBottomSheetContent = () => {
  const navigation =
    useNavigation<NavigationProp<PrivateStackNavigatorParamList>>();
  const { proposal, setTradeType } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      setTradeType: state.setTradeType,
    })),
  );
  const { desktop } = useMedia();
  const { closeBottomSheet } = useGlobalBottomSheetStore(
    useShallow((state) => ({ closeBottomSheet: state.closeBottomSheet })),
  );
  const handleOnPress = useCallback(
    (type: CreateTradeRequestDtoType) => {
      closeBottomSheet();

      setTradeType(type);

      setTimeout(() => {
        navigation.navigate('TradeScreens', {
          screen: 'TradeOrder',
        });
      }, 500);
    },
    [closeBottomSheet, navigation, setTradeType],
  );

  return (
    <YStack justifyContent={'center'} padding={'$3'} flex={1}>
      <XStack
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingBottom={'$1'}
      >
        <HeadingL fontWeight={'$3'}>
          {proposal.components[0].product.name}
        </HeadingL>
        <Pressable onPress={closeBottomSheet}>
          <Icon iconName={'closeIcon'} color={'$mono700'} size={'$6'} />
        </Pressable>
      </XStack>
      {/* Font already has a space on some numbers */}
      <BodyL x={-getSizeTokenValue('$1')} color={'$mono900'}>
        {` ${proposal.components[0].product.coupon} ${getShortMonthName(
          proposal.components[0].product.month,
        )}`}
      </BodyL>
      <XStack
        justifyContent={desktop ? 'center' : 'space-between'}
        space={'$3'}
        paddingVertical={'$9'}
      >
        <ButtonLarge
          onPress={() => handleOnPress('BID')}
          variant={'primary'}
          flex={1}
        >
          <LabelL fontWeight={'$3'} color={'$mono100'}>
            {'Bid'}
          </LabelL>
        </ButtonLarge>
        <ButtonLarge
          onPress={() => handleOnPress('OFFER')}
          variant={'primary'}
          flex={1}
        >
          <LabelL fontWeight={'$3'} color={'$mono100'}>
            {'Offer'}
          </LabelL>
        </ButtonLarge>
        <ButtonLarge
          onPress={() => handleOnPress('SWAP')}
          variant={'primary'}
          flex={1}
        >
          <LabelL fontWeight={'$3'} color={'$mono100'}>
            {'Swap'}
          </LabelL>
        </ButtonLarge>
      </XStack>
      <BodyM
        fontSize={'$4'}
        color={'$mono900'}
        textAlign={'center'}
        paddingBottom={'$9'}
      >
        {'To Roll, go back and choose a month combination.'}
      </BodyM>
    </YStack>
  );
};

export { OperationsBottomSheetContent };

import type { TradeRequestComponentDto } from '@utility-nyc/react-query-sdk';

import { useMemo } from 'react';

import { YStack } from 'tamagui';

import {
  capitalizeFirstLetter,
  formatToMoney,
  formattedProductName,
} from '@shared/utils';
import { HeadingM, LabelXL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';

import { DisplayTradeItem } from './DisplayTradeItem';

type DisplayTradeInfoProps = {
  order: TradeRequestComponentDto;
  isAlternativeLabel?: boolean;
};

const DisplayTradeInfo = ({
  order,
  isAlternativeLabel = false,
}: DisplayTradeInfoProps) => {
  const { product, amount, type } = order;
  const OperationText = isAlternativeLabel ? LabelXL : HeadingM;

  const color = isAlternativeLabel ? '$mono900' : '$mono900';
  const fontWeight = isAlternativeLabel ? '$2' : '$3';

  const formattedType = useMemo(() => capitalizeFirstLetter(type), [type]);

  const formattedAmount = useMemo(() => `$${formatToMoney(amount)}`, [amount]);

  const productName = useMemo(() => formattedProductName(product), [product]);

  return (
    <YStack>
      <OperationText
        paddingTop={'$1'}
        paddingBottom={'$6'}
        color={color}
        fontWeight={fontWeight}
      >
        {formattedType}
      </OperationText>
      <YStack>
        <DisplayTradeItem label={en_US.amount} value={formattedAmount} />
        <DisplayTradeItem label={en_US.product} value={productName} />
      </YStack>
    </YStack>
  );
};

export { DisplayTradeInfo };

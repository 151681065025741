const en_US = {
  logout: 'Log out',
  name: 'Name',
  email: 'Email',
  company: 'Company',
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms and Conditions',
  aboutSS: 'About South Street Securities',
  amount: 'Amount',
  product: 'Product',
  historyList: {
    emptyList:
      'Transactions will show once you start trading within the trading tab.',
  },
  canceledByTrader: 'Canceled by trader',
  canceledByCustomer: 'Canceled by customer',
  loadingPastTransactions: 'Loading past transactions',
  welcomeBack: 'Welcome back',
  continue: 'Continue',
  forgotPassword: 'Forgot username/password?',
  forgotPasswordSuccess: 'Password reset link sent',
  emailIsRequired: 'Email is required',
  pleaseEnterValidEmail: 'Please enter a valid email address',
  enterPassword: 'Enter password',
  unableToVerifyAccount:
    'We were unable to verify your account info. Please double check your username and password.',
  passwordIsRequired: 'Password is required',
  password: 'Password',
  passwordRules: {
    eightCharacters: '8 characters',
    oneUppercaseCharacter: 'one uppercase character',
    oneLowercaseCharacter: 'one lowercase character',
    oneNumber: 'one number',
    oneSpecialCharacter: 'one special character (!@#$^&*)',
  },
  setPassword: 'Set password',
  passwordMustContain:
    'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character',
  incorrectCode: 'Incorrect code, please try again.',
  codeSent:
    'Code sent to your phone number, unless you already have an account.',
  enter6DigitCode: 'Please enter a 6 digit code.',
  sixDigitCode: '6-digit code',
  resetPassword: 'Reset your password',
  resetPasswordSubtext: `Enter your email and we'll send a link to reset your password`,
  sendEmail: 'Send email',
  type: 'Type',
  coupon: 'Coupon',
  color: 'Color',
  roll: 'Roll',
  swap: 'Swap',
  submitTradeRequest: 'Submit trade request',
  confirmTrade: 'Confirm trade',
  accept: 'Accept',
  reject: 'Reject',
  acceptOrRejectQuotedPrice: 'Please accept or reject the quoted price.',
  quotedPrice: 'Quoted price',
  waitingTraderToQuotePrice: 'Waiting on South Street trader to quote a price.',
  traderIsConfirmingPrice: 'South Street trader is confirming the price',
  requestingTrade: 'Requesting trade',
  cancelTrade: 'Cancel trade',
  spread: 'Spread',
  settledDate: 'Trade date',
  finalPrice: 'Final price',
  congratulationsTradeWasExecuted: 'Congratulations! Trade was executed.',
  goHome: 'Go home',
  previewTrade: 'Preview trade',
  chooseProduct: 'Choose product',
  traderHasReadjustedTheQuote: 'Trader has re-adjusted the quote',
  reviewNewQuotedPrice: 'Please review the new quoted price',
  awaitingTraderConfirmation: 'Awaiting Trader Confirmation',
  tradeWasNotCompleted:
    'The trade was not completed. You can go back home and start a new trade or reach out to a trader for assistance.',
  chatWithTrader: 'Chat with trader',
  reSubmitTrade: 'Re-submit trade',
  login: 'Log in',
  invitationExpired:
    'Oops, it looks like the invitation expired. Please ask your South Street representative to send the invite link again.',
  somethingWentWrong: 'Oops, something went wrong',
};

export { en_US };

import type { TradeRequestComponentDto } from '@utility-nyc/react-query-sdk';

import { useMemo } from 'react';

import { YStack, XStack } from 'tamagui';

import {
  apiSpecToQuoteNumber,
  capitalizeFirstLetter,
  formatToMoney,
  getShortMonthName,
  stringifyQuoteNumber,
} from '@shared/utils';
import { BodyL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';

type CounterproposalContentProps = {
  component: TradeRequestComponentDto;
  index: number;
  isMoreThanOneComponent: boolean;
  isLastComponent: boolean;
  isExecuted: boolean;
};

type RowProps = {
  title: string;
  label: string;
};

const Row = ({ title, label }: RowProps) => (
  <XStack justifyContent={'space-between'} paddingTop={'$4'}>
    <BodyL>{title}</BodyL>
    <BodyL>{label}</BodyL>
  </XStack>
);

const CounterproposalContent = ({
  component,
  index,
  isMoreThanOneComponent,
  isLastComponent,
  isExecuted,
}: CounterproposalContentProps) => {
  const notLastButMultiple = isMoreThanOneComponent && !isLastComponent;

  const formattedType = useMemo(
    () => capitalizeFirstLetter(component.type),
    [component.type],
  );

  const formattedAmount = useMemo(
    () => `$${formatToMoney(component.amount)}`,
    [component.amount],
  );

  const fullProductName = useMemo(
    () =>
      `${component.product.name} ${
        component.product.coupon
      } ${getShortMonthName(component.product.month)}`,
    [component.product.coupon, component.product.month, component.product.name],
  );

  const formattedPrice = useMemo(
    () => stringifyQuoteNumber(apiSpecToQuoteNumber(component.price)),
    [component.price],
  );

  const quoteLabel = isExecuted ? 'Final price' : 'Quote';

  const borderBottomWidth = notLastButMultiple ? '$px' : undefined;

  const borderBottomColor = notLastButMultiple ? '$mono300' : undefined;

  const paddingBottom = notLastButMultiple ? '$4' : '$0';

  return (
    <YStack
      key={index}
      borderBottomWidth={borderBottomWidth}
      borderBottomColor={borderBottomColor}
      paddingBottom={paddingBottom}
    >
      {isMoreThanOneComponent ? (
        <BodyL fontWeight={'$3'} paddingTop={'$4'}>
          {formattedType}
        </BodyL>
      ) : null}
      <Row title={en_US.amount} label={formattedAmount} />
      <Row title={en_US.product} label={fullProductName} />
      <Row title={quoteLabel} label={formattedPrice} />
    </YStack>
  );
};

export { CounterproposalContent };
